import { Pipe, PipeTransform } from '@angular/core';

import { ArticleExportState, ArticleExportStateType } from '@mp/content-manager/exports/data-access';

import { ExportMessage, ExportMessageSeverity } from '../../models';

@Pipe({
  name: 'mpcmExportMessages',
  standalone: true,
})
export class ExportMessagesPipe implements PipeTransform {
  transform(state: ArticleExportState): ExportMessage[] {
    if (state.type === ArticleExportStateType.Successful) {
      return this.getWarningMessages(state.warningMessages);
    } else if (state.type === ArticleExportStateType.Failed) {
      const errorMessage: ExportMessage = { message: state.errorMessage, type: ExportMessageSeverity.Error };
      return [errorMessage, ...this.getWarningMessages(state.warningMessages)];
    }
    return [];
  }

  private getWarningMessages(messages: string[]): ExportMessage[] {
    return messages.map((message) => ({ message, type: ExportMessageSeverity.Warning }));
  }
}
