import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { ListHeaderComponent } from '@core/ui';

@Component({
  selector: 'mpcm-exports-content-header',
  standalone: true,
  templateUrl: './exports-content-header.component.html',
  styleUrls: ['./exports-content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatIconModule, ListHeaderComponent],
})
export class ExportsContentHeaderComponent {
  @Input() numberOfAllItems = 0;
}
