import { Injectable } from '@angular/core';

import { ExportFacade, ExportsFilters } from '@mp/content-manager/exports/data-access';
import { ExportsFilter } from '@mp/content-manager/exports/ui';

import { QueryParamsService } from './query-params.service';

@Injectable()
export class ExportsPageFacade {
  private readonly defaultPagination = { page: 1, pageSize: 50 };

  constructor(
    private facade: ExportFacade,
    private queryParamsService: QueryParamsService,
  ) {}

  setFormFilters(formFilters: ExportsFilter): void {
    this.fetchExports({ ...formFilters, page: 1 });
  }

  resetFormFilters(): void {
    this.fetchExports({
      ...this.defaultPagination,
      stateType: '',
      exportTypes: [],
      searchTerm: '',
      warningsOnly: false,
      numberOfDays: 90,
    });
  }

  setTimeRange(numberOfDays: number): void {
    this.fetchExports({ numberOfDays, ...this.defaultPagination });
  }

  setPagination(pagination: Pick<ExportsFilters, 'page' | 'pageSize'>): void {
    this.fetchExports(pagination);
  }

  private fetchExports(filters: Partial<ExportsFilters>): void {
    this.facade.fetchExports(filters);
    this.queryParamsService.updateQueryParams(filters);
  }
}
