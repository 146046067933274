<table class="export-items-table top-border" mat-table [dataSource]="exportItems()" [trackBy]="trackByFn">
  <ng-container matColumnDef="export">
    <th
      mat-header-cell
      class="export-items-table__header-cell export-items-table__header-cell-export"
      *matHeaderCellDef
    >
      <div class="export-header">
        <span class="export-header__type">Typ</span>
        <span class="export-header__name">Name</span>
      </div>
    </th>
    <td mat-cell class="export-items-table__cell export-items-table__cell-export" *matCellDef="let export">
      <ng-template [ngTemplateOutlet]="itemInfoTemplate" [ngTemplateOutletContext]="{ $implicit: export }" />
    </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th
      mat-header-cell
      class="export-items-table__header-cell export-items-table__header-cell-user"
      *matHeaderCellDef
      title="Benutzer"
    >
      Benutzer
    </th>
    <td mat-cell class="export-items-table__cell export-items-table__cell-user" *matCellDef="let export">
      {{ export.creator.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th
      mat-header-cell
      class="export-items-table__header-cell export-items-table__header-cell-date"
      *matHeaderCellDef
      title="Exportdatum"
    >
      Exportdatum
    </th>
    <td mat-cell class="export-items-table__cell export-items-table__cell-date" *matCellDef="let export">
      {{ export.createdAt | humanizeRelativeDate: { whenInRange: { days: 1 } } }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th
      mat-header-cell
      class="export-items-table__header-cell export-items-table__header-cell-actions"
      *matHeaderCellDef
    ></th>
    <td mat-cell class="export-items-table__cell export-items-table__cell-actions" *matCellDef="let export">
      <button mat-icon-button (click)="deleteExportItem(export.articleExportId)">
        <mat-icon>close</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row class="export-items-table__header-row" *matHeaderRowDef="columns"></tr>
  <tr mat-row class="export-items-table__row" *matRowDef="let export; columns: columns"></tr>
</table>
