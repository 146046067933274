import { Injectable, Optional } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { filterUndefined } from '@core/shared/util';
import { FilterInputOptionsData, FilterSection } from '@core/ui';
import { ArticleExportStateType, ExportJobProvider } from '@mp/content-manager/exports/data-access';
import { InsightsBaseEvent, insightsNamedEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

import { ExportsFilter } from '../../models';

import { createExportTypeFilterSection, createStateTypeFilterSection } from './article-exports-filter-sections';

type ExportsFilterSection = FilterSection<string, FilterInputOptionsData>;

export interface ExportsFilterState {
  exportsFilter: Pick<ExportsFilter, 'stateType' | 'exportTypes'>;
  exportsJobProviders: readonly ExportJobProvider[];
  stateTypeFilterSection: ExportsFilterSection | undefined;
  exportTypeFilterSection: ExportsFilterSection | undefined;
}

export const INITIAL_STATE: ExportsFilterState = {
  exportsFilter: {
    stateType: '',
    exportTypes: [],
  },
  exportsJobProviders: [],
  stateTypeFilterSection: undefined,
  exportTypeFilterSection: undefined,
};

@Injectable()
export class ExportsFilterStore extends ComponentStore<ExportsFilterState> {
  constructor(@Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null) {
    super(INITIAL_STATE);
  }

  readonly stateTypeFilterSection$: Observable<ExportsFilterSection | undefined> = this.select(
    (state) => state.stateTypeFilterSection,
  );

  readonly exportTypeFilterSection$: Observable<ExportsFilterSection | undefined> = this.select(
    (state) => state.exportTypeFilterSection,
  );

  readonly filterSections$: Observable<ExportsFilterSection[]> = this.select(
    this.stateTypeFilterSection$.pipe(filterUndefined()),
    this.exportTypeFilterSection$.pipe(filterUndefined()),
    (stateTypeFilterSection, exportTypeFilterSection) => [stateTypeFilterSection, exportTypeFilterSection],
    { debounce: true },
  );

  readonly setExportsFilters = this.updater(
    (state, exportsFilter: ExportsFilter): ExportsFilterState => ({
      ...state,
      exportsFilter,
      stateTypeFilterSection: this.createStateTypeFilterSection(exportsFilter.stateType),
      exportTypeFilterSection: this.createExportTypeFilterSection(
        this.get().exportsJobProviders,
        exportsFilter.exportTypes,
      ),
    }),
  );

  readonly setExportsJobProviders = this.updater(
    (state, exportsJobProviders: readonly ExportJobProvider[]): ExportsFilterState => ({
      ...state,
      exportsJobProviders,
      exportTypeFilterSection: this.createExportTypeFilterSection(
        exportsJobProviders,
        this.get().exportsFilter.exportTypes,
      ),
    }),
  );

  private createStateTypeFilterSection(
    stateType: ArticleExportStateType | '',
  ): FilterSection<string, FilterInputOptionsData> {
    const stateTypeFilterSectionBase: FilterSection<string, FilterInputOptionsData> =
      createStateTypeFilterSection(stateType);

    return this.addInsightsTrackingToFilterSection(stateTypeFilterSectionBase);
  }

  private createExportTypeFilterSection(
    providers: readonly ExportJobProvider[],
    exportTypes?: string[],
  ): FilterSection<string, FilterInputOptionsData> {
    const exportTypeFilterSectionBase: FilterSection<string, FilterInputOptionsData> = createExportTypeFilterSection(
      providers,
      exportTypes,
    );

    return this.addInsightsTrackingToFilterSection(exportTypeFilterSectionBase);
  }

  private addInsightsTrackingToFilterSection(filterSection: ExportsFilterSection): ExportsFilterSection {
    const filterSectionData: FilterInputOptionsData = {
      ...filterSection.data,
      onExpandedChange: (isExpanded: boolean) => this.trackFilterExpansionChange(isExpanded, filterSection.label),
      onValueSelect: ({ label }: { value: unknown; label: string }) =>
        this.trackFilterValueSelect(label, filterSection.label),
    };

    return { ...filterSection, data: filterSectionData };
  }

  private trackFilterExpansionChange(isExpanded: boolean, filterName: string): void {
    const filtersExpansionEvent: InsightsBaseEvent = isExpanded
      ? InsightsBaseEvent.FILTER_EXPAND
      : InsightsBaseEvent.FILTER_COLLAPSE;
    this.insightsEventsTrackingService?.trackEvent(insightsNamedEvent(filterName, filtersExpansionEvent));
  }

  private trackFilterValueSelect(valueLabel: string, filterName: string): void {
    this.insightsEventsTrackingService?.trackEvent(insightsNamedEvent(filterName, InsightsBaseEvent.FILTER_CHANGE), {
      value: valueLabel,
    });
  }
}
