<ul class="messages-list" [ngStyle]="{ height: listHeight }">
  <cdk-virtual-scroll-viewport [itemSize]="ITEM_SIZE">
    <li
      *cdkVirtualFor="let exportMessage of messages; trackBy: trackByFn"
      class="messages-list__item truncate"
      [title]="exportMessage.message"
      [ngClass]="{
        'messages-list__item--error': exportMessage.type === ExportMessageSeverity.Error,
        'messages-list__item--warning': exportMessage.type === ExportMessageSeverity.Warning
      }"
    >
      {{ exportMessage.message }}
    </li>
  </cdk-virtual-scroll-viewport>
</ul>
