import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { Export } from '@mp/content-manager/exports/data-access';

import { ExportItemIconNamePipe } from './export-item-icon-name.pipe';

@Component({
  selector: 'mpcm-export-item-icon',
  standalone: true,
  templateUrl: './export-item-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, ExportItemIconNamePipe],
})
export class ExportItemIconComponent {
  @Input() exportItem!: Export;
}
