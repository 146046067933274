export enum ExportHistoryInsightsEvent {
  NAVIGATE_BACK = 'Exporthistorie zurück',

  PERFORM_SEARCH = 'Suche',
  CLEAR_SEARCH_TERM = 'Suchbegriff entfernen',
  ENABLE_SHOW_WARNINGS_ONLY = 'Filter Warnungen on',
  DISABLE_SHOW_WARNINGS_ONLY = 'Filter Warnungen off',

  EXPORT_ITEM_DELETE = 'Eintrag löschen',
  EXPORT_ITEM_DOWNLOAD = 'Eintrag herunterladen',
  EXPORT_ITEM_DETAILS_ACCESS = 'Eintrag Hangfire öffnen',
  EXPORT_ITEM_EXPAND = 'Eintrag öffnen',

  HISTORY_DURATION_DROPDOWN_CLICK = 'Historiendauer öffnen',
  HISTORY_DURATION_CHANGE = 'Historiendauer Eintrag',
}
