import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash';

const units = ['KB', 'MB', 'GB'];

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(fileSizeInBytes: number): string {
    let size: number = fileSizeInBytes / 1024;

    let unitIndex = 0;
    while (size >= 1000 && unitIndex + 1 < units.length) {
      size /= 1024;
      unitIndex++;
    }

    return `${round(size, 2).toLocaleString('de-DE')} ${units[unitIndex]}`;
  }
}
