<mat-expansion-panel
  class="export-item-panel"
  [hideToggle]="true"
  [(expanded)]="showMessages"
  (expandedChange)="onExpandedChange($event)"
  [disabled]="isPanelExpansionDisabled()"
>
  <mat-expansion-panel-header class="export-item-panel-header">
    <div class="panel-header-wrapper">
      <div class="panel-header-wrapper__image">
        <mpcm-export-item-icon class="panel-header-wrapper__image-icon" [exportItem]="exportItem" />
      </div>
      <div class="export-item-details">
        <div class="export-item-details__name truncate" [title]="exportItem.name">
          <strong>{{ exportItem.name }}</strong>
          @if (isInSuccessfulState(exportItem.state) && exportItem.state.fileInfo?.fileExtension) {
            {{ exportItem.state.fileInfo?.fileExtension | lowercase }}
          }
        </div>
        <div class="export-item-info">
          <span>{{ exportItem.exportTypeDisplayName }}</span>
          @if (exportItem.articleCount || exportItem.articleCount === 0) {
            <span>
              <strong>
                {{ exportItem.articleCount | number }}
              </strong>
              Artikel
            </span>
          }

          @if (
            isInSuccessfulState(exportItem.state) &&
            (exportItem.state.fileInfo?.size || exportItem.state.fileInfo?.size === 0)
          ) {
            <span>
              <strong>
                {{ exportItem.state.fileInfo!.size | fileSize }}
              </strong>
            </span>
          }

          @if (isInFailedState(exportItem.state)) {
            <span class="export-item-info__status export-item-info__status--error"> 1 Fehler </span>
          }

          @if (!isInPendingState(exportItem.state) && exportItem.state.warningTotalCount) {
            <span class="export-item-info__status export-item-info__status--warning">
              {{ exportItem.state.warningTotalCount | autoSingularOrPlural: 'Warnung' : 'Warnungen' }}
            </span>
          }
        </div>
        <div></div>
      </div>
      <div class="export-item-actions">
        @if (isInFailedState(exportItem.state) && exportItem.state.jobId != null) {
          <button (click)="regenerate()" mat-button color="warn">Erneut exportieren</button>
        }

        @if (jobDetailsPageLink) {
          <a
            mat-icon-button
            color="accent"
            [href]="jobDetailsPageLink"
            [mpAppInsightsEvent]="ExportHistoryInsightsEvent.EXPORT_ITEM_DETAILS_ACCESS"
            target="_blank"
            matTooltip="Job Details anzeigen"
            matTooltipPosition="above"
          >
            <mat-icon>open_in_new</mat-icon>
          </a>
        }

        @if (!exportItem.downloadLink) {
          <button mat-raised-button disabled>Herunterladen</button>
        }

        @if (exportItem.downloadLink) {
          <a
            [href]="exportItem.downloadLink"
            (click)="$event.stopPropagation()"
            [mpAppInsightsEvent]="ExportHistoryInsightsEvent.EXPORT_ITEM_DOWNLOAD"
            target="_blank"
            rel="noopener noreferrer"
            mat-raised-button
            color="accent"
          >
            Herunterladen
          </a>
        }
      </div>
      <div class="export-item-expand-indicator">
        @if (!isPanelExpansionDisabled()) {
          <mat-icon>
            {{ showMessages ? 'expand_less' : 'expand_more' }}
          </mat-icon>
        }
      </div>
    </div>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <mpcm-export-item-messages [messages]="exportItem.state | mpcmExportMessages" />
  </ng-template>
</mat-expansion-panel>
