<ng-container *mpHeaderElement="'left'; let activatedRouteTitle">
  <mp-root-module-nav
    [label]="activatedRouteTitle"
    [rootModuleLink]="['/', 'artikel', 'start']"
    (activated)="trackInsightsEvent(ArticleSearchInsightsEvent.START_PAGE_NAVIGATION)"
  />

  <mp-navigate-back-button *ngrxLet="backlink$ as backLink" (navigateBackClick)="navigateBack(backLink)" />
</ng-container>

<mp-aside-buttons *mpHeaderElement="'right'" />

<div class="mp-page mp-page--scrollable mp-page--border">
  <div class="mp-page__main mp-page__main--fixed-layout">
    <mp-aside-filters
      class="mp-page__aside-filters"
      [filtersSummary]="(asideFiltersSummary$ | async) ?? []"
      [trackInsightsEvents]="true"
      (filtersReset)="resetFilters()"
    >
      <mpcm-exports-filter
        [exportsFilter]="(exportsFilters$ | async)!"
        [exportsJobProviders]="(exportsJobProviders$ | async)!"
        (exportsFilterChange)="onExportsFilterChange($event)"
      />
    </mp-aside-filters>

    <div class="mp-page__content mp-page--padding" *ngrxLet="isLoaded$ as isLoaded">
      <ng-container *ngrxLet="numberOfAllItems$ as numberOfAllItems">
        <div class="topbar-wrapper">
          <mpcm-exports-content-header
            class="topbar-wrapper__content-header truncate"
            [numberOfAllItems]="numberOfAllItems"
          />

          <mp-dropdown
            class="topbar-wrapper__time-range-selector"
            [options]="numberOfDaysOptions"
            label="Historiendauer"
            [selectedValue]="(numberOfDays$ | async)!"
            (selectedValueChange)="onNumberOfDaysChange($event)"
            (click)="onHistoryDurationDropdownClick()"
          />
        </div>

        @if (isLoaded) {
          <mpcm-export-items [exportItems]="(exportItems$ | async) ?? []" (itemDelete)="deleteExportItem($event)">
            <ng-template let-exportItem>
              <mpcm-export-item-row-panel
                [exportItem]="exportItem"
                [jobDetailsPageLink]="(exportItem.state.jobId | mpJobDetailsPageLink)!"
                (regenerateClick)="regenerateExportItem(exportItem.articleExportId)"
              />
            </ng-template>
          </mpcm-export-items>

          @if (paginationMetadata$ | async; as pagination) {
            <mp-paginator [pagination]="pagination" (page)="onPageChange($event)" />
          }
        } @else {
          <mp-spinner />
        }
      </ng-container>
    </div>
  </div>
</div>
