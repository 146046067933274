import { Pipe, PipeTransform } from '@angular/core';

import { HangfireFacade } from '@mp/shared/data-access';
import { JobServerPermissions } from '@mp/shared/feature';
import { LoginContextService } from '@mpk/shared/data-access';

@Pipe({
  name: 'mpJobDetailsPageLink',
  standalone: true,
})
export class JobDetailsPageLinkPipe implements PipeTransform {
  private readonly hasViewJobsPermission = this.loginContextService.permission(JobServerPermissions.ViewJobs);

  constructor(
    private readonly loginContextService: LoginContextService,
    private readonly hangfireFacade: HangfireFacade,
  ) {}

  transform(jobId: string | undefined): string | undefined {
    if (!jobId || !this.hasViewJobsPermission()) {
      return undefined;
    }

    return this.hangfireFacade.getHangfireUrl(jobId);
  }
}
