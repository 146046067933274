<mp-search-field
  #searchField
  class="raised"
  placeholder="Nach Export/Benutzer suchen"
  [value]="filtersForm.get('searchTerm')?.value ?? ''"
  (searched)="onSearchbarSubmitted($event)"
>
  <button mat-icon-button mpSearchFieldButton="left" (click)="onSearchbarSubmitted(searchField.value)">
    <mat-icon>search</mat-icon>
  </button>

  @if (searchField.value.length > 0) {
    <button mat-icon-button mpSearchFieldButton="right" (click)="searchField.clear(); onSearchbarClear()">
      <mat-icon>clear</mat-icon>
    </button>
  }
</mp-search-field>

<mpcm-warning-state-filter
  [active]="filtersForm.get('warningsOnly')?.value ?? false"
  (activeChange)="handleWarningStateFilterChange($event)"
/>

@if (filterSections$ | async; as filterSections) {
  <mp-filter-input-options [filterSections]="filterSections" (valueChanges)="handleFilterChange($event)" />
}
