import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { IconDirective } from '@core/shared/util';

@Component({
  selector: 'mpcm-warning-state-filter',
  standalone: true,
  templateUrl: './warning-state-filter.component.html',
  styleUrls: ['./warning-state-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, MatIconModule, MatSlideToggleModule, IconDirective],
})
export class WarningStateFilterComponent {
  @HostBinding('class')
  readonly class = 'mpcm-warning-state-filter';

  @Input()
  active = false;

  @Output() readonly activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onToggleChange(checked?: boolean): void {
    this.active = checked ?? !this.active;
    this.activeChange.emit(checked);
  }
}
