import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { setupFeatureFlagsForModule } from '@core/shared/util';

import { ExportsComponent } from './components/exports/exports.component';

const EXPORTS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    data: setupFeatureFlagsForModule({ customHeader: true }),
    component: ExportsComponent,
    title: 'Artikelsuche | Exporthistorie',
  },
];

@NgModule({
  imports: [RouterModule.forChild(EXPORTS_ROUTES)],
  exports: [RouterModule],
})
export class ExportsFeatureRoutingModule {}
