import { ArticleExportStateType } from '@mp/content-manager/exports/data-access';

import { ArticleExportStateOption } from '../../models';

export const ARTICLE_EXPORT_STATE_OPTIONS: ArticleExportStateOption[] = [
  {
    key: '',
    label: 'Alle',
  },
  {
    key: ArticleExportStateType.Successful,
    label: 'Erfolgreich',
  },
  {
    key: ArticleExportStateType.Pending,
    label: 'In Bearbeitung',
  },
  {
    key: ArticleExportStateType.Failed,
    label: 'Fehler',
  },
];
