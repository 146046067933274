import { permissions } from '@mpk/shared/domain';

enum ExportGhxPermissionsEnum {
  None = 0,
  /**
   * Export Articles as GHX format file.
   */
  UseExport = 1,
}

export const ExportGhxPermissions = permissions(ExportGhxPermissionsEnum, {
  featureId: 'ExportGhx',
});
