import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { ExportMessage, ExportMessageSeverity } from '../../models';

@Component({
  selector: 'mpcm-export-item-messages',
  standalone: true,
  templateUrl: './export-item-messages.component.html',
  styleUrls: ['./export-item-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgStyle, ScrollingModule],
})
export class ExportItemMessagesComponent {
  @HostBinding() readonly class = 'mpcm-export-item-messages';

  @Input() set messages(messages: ExportMessage[]) {
    this._messages = messages;
    this.setListHeight();
  }

  get messages(): ExportMessage[] {
    return this._messages;
  }

  _messages: ExportMessage[] = [];

  readonly ExportMessageSeverity = ExportMessageSeverity;

  readonly ITEM_SIZE: number = 30;
  private readonly MAX_ELEMENTS_VISIBLE = 5;

  listHeight = '';

  private setListHeight(): void {
    const height = Math.min(this.messages.length, this.MAX_ELEMENTS_VISIBLE) * this.ITEM_SIZE;
    this.listHeight = `${height}px`;
  }

  trackByFn(index: number): number {
    return index;
  }
}
