import { DecimalPipe, LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Optional, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { UtilPipesModule } from '@core/shared/util';
import {
  ArticleExportFailedState,
  ArticleExportPendingState,
  ArticleExportState,
  ArticleExportStateType,
  ArticleExportSuccessfulState,
} from '@mp/content-manager/exports/data-access';
import { ExportHistoryInsightsEvent, FileSizePipe } from '@mp/content-manager/exports/util';
import { AppInsightsEventDirective } from '@mp/shared/app-insights/ui';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { ExportInsightsEvent } from '@mp/shared/util';

import { ExportItem } from '../../models';
import { ExportItemIconComponent } from '../export-item-icon/export-item-icon.component';
import { ExportItemMessagesComponent } from '../export-item-messages/export-item-messages.component';

import { ExportMessagesPipe } from './export-messages.pipe';

@Component({
  selector: 'mpcm-export-item-row-panel',
  standalone: true,
  templateUrl: './export-item-row-panel.component.html',
  styleUrl: './export-item-row-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DecimalPipe,
    LowerCasePipe,

    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,

    ExportItemIconComponent,
    ExportItemMessagesComponent,
    FileSizePipe,
    UtilPipesModule,
    AppInsightsEventDirective,
    ExportMessagesPipe,
  ],
})
export class ExportItemRowPanelComponent {
  @HostBinding() readonly class = 'mpcm-export-item-row-panel';

  @Input() exportItem!: ExportItem;

  @Input() jobDetailsPageLink: string | undefined = undefined;

  @Output() readonly regenerateClick: EventEmitter<void> = new EventEmitter<void>();

  readonly ArticleExportStateType = ArticleExportStateType;
  readonly ExportHistoryInsightsEvent: typeof ExportHistoryInsightsEvent = ExportHistoryInsightsEvent;

  showMessages = false;

  constructor(@Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null) {}

  regenerate(): void {
    this.insightsEventsTrackingService?.trackEvent(ExportInsightsEvent.START_EXPORT, {
      value: this.exportItem.exportType,
    });
    this.regenerateClick.emit();
  }

  isInFailedState(state: ArticleExportState): state is ArticleExportFailedState {
    return state.type === ArticleExportStateType.Failed;
  }

  isInSuccessfulState(state: ArticleExportState): state is ArticleExportSuccessfulState {
    return state.type === ArticleExportStateType.Successful;
  }

  isInPendingState(state: ArticleExportState): state is ArticleExportPendingState {
    return state.type === ArticleExportStateType.Pending;
  }

  isPanelExpansionDisabled(): boolean {
    const state: ArticleExportState = this.exportItem.state;
    if (this.isInPendingState(state)) {
      return true;
    }
    if (this.isInFailedState(state)) {
      return false;
    }

    return state.warningTotalCount === 0;
  }

  onExpandedChange(isExpanded: boolean): void {
    if (isExpanded) {
      this.insightsEventsTrackingService?.trackEvent(ExportHistoryInsightsEvent.EXPORT_ITEM_EXPAND);
    }
  }
}
