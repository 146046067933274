import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { flattenDeep } from 'lodash';

import { ArticleExportStateType, ExportFacade, ExportsFilters } from '@mp/content-manager/exports/data-access';

@Injectable()
export class QueryParamsService {
  constructor(private router: Router, private facade: ExportFacade, private activatedRouter: ActivatedRoute) {
    const queryParams = this.activatedRouter.snapshot.queryParams;
    const filterExports = this.convertToExportsFilters(queryParams);
    this.facade.fetchExports(filterExports);
  }

  updateQueryParams(exportFilters: Partial<ExportsFilters>): void {
    this.router.navigate([], {
      queryParams: this.removeDefaultValues(exportFilters),
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
  }

  private removeDefaultValues(exportFilters: Partial<ExportsFilters>): Params {
    return {
      ...exportFilters,
      stateType: exportFilters.stateType !== '' ? exportFilters.stateType : undefined,
      searchTerm: exportFilters.searchTerm !== '' ? exportFilters.searchTerm : undefined,
      page: exportFilters.page !== 1 ? exportFilters.page : undefined,
      warningsOnly: exportFilters.warningsOnly || undefined,
    };
  }

  private convertToExportsFilters(queryParams: Params): ExportsFilters {
    return {
      stateType: this.getStatus(queryParams),
      exportTypes: this.getTypes(queryParams),
      searchTerm: queryParams['searchTerm'] || '',
      warningsOnly: queryParams['warningsOnly'] === 'true',
      numberOfDays: parseInt(queryParams['numberOfDays'] || 90),
      page: parseInt(queryParams['page'] || 1),
      pageSize: parseInt(queryParams['pageSize'] || 50),
    };
  }

  private getTypes(queryParams: Params): Array<string> {
    return flattenDeep([queryParams['exportTypes'] || []]);
  }

  private getStatus(queryParams: Params): ArticleExportStateType | '' {
    const availableStatuses = [
      ArticleExportStateType.Failed,
      ArticleExportStateType.Pending,
      ArticleExportStateType.Successful,
    ];
    if (availableStatuses.indexOf(queryParams['stateType']) >= 0) {
      return queryParams['stateType'];
    }
    return '';
  }
}
