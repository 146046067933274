import { permissions } from '@mpk/shared/domain';

enum ExportMediioPermissionsEnum {
  None = 0,
  /**
   * Export Articles as Mediio condition format file.
   */
  UseExport = 1,
}

export const ExportMediioPermissions = permissions(ExportMediioPermissionsEnum, {
  featureId: 'ExportMediio',
});
