import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Optional } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Pagination } from '@core/shared/domain';
import { HeaderElementDirective, UrlService } from '@core/shared/util';
import {
  AsideFiltersComponent,
  DropdownComponent,
  FilterInfo,
  NavigateBackButtonComponent,
  PaginationConfig,
  PaginatorComponent,
  RootModuleNavComponent,
  SelectOption,
  SpinnerComponent,
} from '@core/ui';
import { ArticleSearchInsightsEvent } from '@mp/content-manager/artikelsuche/util';
import {
  Export,
  ExportFacade,
  ExportJobProvider,
  ExportsDataAccessModule,
  ExportsFilters,
} from '@mp/content-manager/exports/data-access';
import {
  ExportItem,
  ExportItemRowPanelComponent,
  ExportItemsComponent,
  ExportsContentHeaderComponent,
  ExportsFilter,
  ExportsFilterComponent,
} from '@mp/content-manager/exports/ui';
import { ExportHistoryInsightsEvent } from '@mp/content-manager/exports/util';
import { InsightsBaseEvent, InsightsEvent, InsightsEventProperties } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';
import { AsideButtonsComponent } from '@mp/shared/feature';

import { ExportsPageFacade, QueryParamsService } from '../../services';

import { JobDetailsPageLinkPipe } from './job-details-page-link.pipe';

@Component({
  selector: 'mp-exports',
  standalone: true,
  templateUrl: './exports.component.html',
  styleUrl: './exports.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    LetDirective,

    MatIconModule,

    AsideButtonsComponent,
    SpinnerComponent,
    ExportItemsComponent,
    ExportsFilterComponent,
    ExportsContentHeaderComponent,
    ExportItemRowPanelComponent,
    ExportsDataAccessModule,
    JobDetailsPageLinkPipe,
    HeaderElementDirective,
    RootModuleNavComponent,
    NavigateBackButtonComponent,
    AsideFiltersComponent,
    DropdownComponent,
    PaginatorComponent,
  ],
  providers: [ExportsPageFacade, QueryParamsService],
})
export class ExportsComponent {
  @HostBinding() readonly class = 'mp-exports';

  readonly isLoaded$: Observable<boolean> = this.facade.isLoaded$;

  readonly numberOfAllItems$: Observable<number> = this.facade.numberOfAllItems$;

  readonly exportsFilters$: Observable<ExportsFilters> = this.facade.filters$;

  readonly asideFiltersSummary$: Observable<FilterInfo[]> = this.facade.asideFiltersSummary$;

  readonly exportsJobProviders$: Observable<readonly ExportJobProvider[]> = this.facade.exportJobProviders$.pipe(
    startWith([] as ExportJobProvider[]),
  );

  readonly pagination$: Observable<Pagination> = this.exportsFilters$.pipe(
    map((filters: ExportsFilters) => ({
      page: filters.page,
      pageSize: filters.pageSize,
    })),
  );

  readonly numberOfDays$: Observable<number> = this.exportsFilters$.pipe(
    map((filters: ExportsFilters) => filters.numberOfDays),
  );

  readonly exportItems$: Observable<ExportItem[]> = combineLatest([
    this.facade.list$,
    this.facade.exportJobProviders$,
  ]).pipe(map(([exports, providers]) => this.getExportsItems(exports, providers)));

  readonly backlink$: Observable<string | undefined> = this.urlService.previousUrl$;

  protected readonly paginationMetadata$: Observable<PaginationConfig> = combineLatest([
    this.numberOfAllItems$,
    this.pagination$,
  ]).pipe(
    map(([numberOfAllItems, { page, pageSize }]) => ({
      currentPage: page,
      totalRowCount: numberOfAllItems,
      limit: pageSize,
    })),
  );

  readonly numberOfDaysOptions: SelectOption<number>[] = [
    { value: 1, label: 'Heute' },
    { value: 7, label: '7 Tage' },
    { value: 30, label: '30 Tage' },
    { value: 90, label: '90 Tage' },
  ];

  protected readonly ArticleSearchInsightsEvent = ArticleSearchInsightsEvent;

  constructor(
    private readonly facade: ExportFacade,
    private readonly exportsPageFacade: ExportsPageFacade,
    private readonly router: Router,
    private readonly urlService: UrlService,
    @Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null,
  ) {
    this.facade.fetchExportJobProviders(true);
  }

  resetFilters(): void {
    this.exportsPageFacade.resetFormFilters();
  }

  deleteExportItem(id: string): void {
    this.trackInsightsEvent(ExportHistoryInsightsEvent.EXPORT_ITEM_DELETE);
    this.facade.deleteExportItem(id);
  }

  regenerateExportItem(id: string): void {
    this.facade.regenerateExportItem(id);
  }

  onPageChange(pagination: Pagination): void {
    this.trackInsightsEvent(InsightsBaseEvent.PAGINATION_CHANGE, {
      page: pagination.page + '',
      pageSize: pagination.pageSize + '',
    });

    this.exportsPageFacade.setPagination(pagination);
  }

  onNumberOfDaysChange(numberOfDays: number | undefined): void {
    if (numberOfDays === undefined) {
      return;
    }

    this.trackInsightsEvent(ExportHistoryInsightsEvent.HISTORY_DURATION_CHANGE, {
      value: numberOfDays + '',
    });
    this.exportsPageFacade.setTimeRange(numberOfDays);
  }

  onHistoryDurationDropdownClick(): void {
    this.trackInsightsEvent(ExportHistoryInsightsEvent.HISTORY_DURATION_DROPDOWN_CLICK);
  }

  onExportsFilterChange(exportsFilter: ExportsFilter): void {
    this.exportsPageFacade.setFormFilters(exportsFilter);
  }

  navigateBack(backlink: string | undefined): void {
    this.trackInsightsEvent(ExportHistoryInsightsEvent.NAVIGATE_BACK);
    backlink ? this.router.navigateByUrl(backlink) : this.router.navigate(['artikel', 'list']);
  }

  private getExportsItems(exports: Export[], providers: readonly ExportJobProvider[]): ExportItem[] {
    const providersByType: Map<string, string> = this.getProvidersByTypeMap(providers);

    return exports.map(({ exportType, ...exportProperties }) => ({
      ...exportProperties,
      exportType,
      exportTypeDisplayName: providersByType.get(exportType) ?? exportType,
    }));
  }

  private getProvidersByTypeMap(providers: readonly ExportJobProvider[]): Map<string, string> {
    return new Map(providers.map(({ exportType, displayName }) => [exportType, displayName]));
  }

  trackInsightsEvent(event: InsightsEvent, customProperties?: InsightsEventProperties): void {
    this.insightsEventsTrackingService?.trackEvent(event, customProperties);
  }
}
