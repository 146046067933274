import { EnvironmentProviders } from '@angular/core';

import { provideModuleInfo } from '@mp/shared/util';
import { provideModulePermissions } from '@mpk/shared/data-access';

import { ExportAmor3Permissions, ExportGhxPermissions, ExportMediioPermissions } from '../permissions';

const moduleName = 'Export';

export function provideExportFeature(): EnvironmentProviders[] {
  return [
    provideModuleInfo(moduleName, {
      displayName: 'Export',
    }),
    provideModulePermissions(moduleName, ExportAmor3Permissions, ExportGhxPermissions, ExportMediioPermissions),
  ];
}
