import { FilterInputOptionsData, FilterItem, FilterSection } from '@core/ui';
import { ArticleExportStateType, ExportJobProvider } from '@mp/content-manager/exports/data-access';

import { ARTICLE_EXPORT_STATE_OPTIONS } from './article-exports-states-options';

interface StateFilterOption {
  key: string;
  label: string;
}

export function createStateTypeFilterSection(
  stateType: ArticleExportStateType | '',
): FilterSection<string, FilterInputOptionsData> {
  return {
    key: 'state',
    label: 'Status',
    icon: 'verified',
    multiselect: false,
    data: {
      expandedInitially: true,
    },
    options: buildOptions(ARTICLE_EXPORT_STATE_OPTIONS, [stateType]),
  };
}

export function createExportTypeFilterSection(
  providers: readonly ExportJobProvider[],
  exportTypes?: string[],
): FilterSection<string, FilterInputOptionsData> {
  return {
    key: 'exportTypes',
    label: 'Typ',
    icon: 'label',
    disabled: !providers.length,
    data: {
      expandedInitially: true,
    },
    options: buildOptions(
      providers.map(({ exportType, displayName }) => ({ key: exportType, label: displayName })),
      exportTypes ?? [],
    ),
  };
}

function buildOptions(items: StateFilterOption[], selectedKeys: string[] = []): FilterItem<string>[] {
  return items.map(
    (item): FilterItem<string> => ({
      ...item,
      value: item.key,
      selected: selectedKeys.indexOf(item.key) >= 0,
      disabled: false,
    }),
  );
}
