import { Pipe, PipeTransform } from '@angular/core';

import { ArticleExportStateType } from '@mp/content-manager/exports/data-access';

@Pipe({
  name: 'mpcmExportItemIconName',
  standalone: true,
})
export class ExportItemIconNamePipe implements PipeTransform {
  transform(stateType: ArticleExportStateType): string {
    return `export_${stateType.toLowerCase()}`;
  }
}
