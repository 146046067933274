<mat-card class="warning-state-filter" (click)="onToggleChange()">
  <mat-icon class="warning-state-filter__icon material-icons-outlined">report_problem</mat-icon>

  <div class="warning-state-filter__title truncate" title="Nur mit Warnungen">Nur mit Warnungen</div>

  <mat-slide-toggle
    class="warning-state-filter__toggle"
    [checked]="active"
    hideIcon
    (change)="onToggleChange($event.checked)"
    (click)="$event.stopPropagation()"
  />
</mat-card>
